.books-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.book-box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px;
  width: 30%;
  box-sizing: border-box;
}
.book-box img {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 5px;
}

  @media (min-width: 992px) {
    .recommendations-footer {
      position: fixed;
      bottom: 0;
      width: 89.7%;
      align-items: center;
      background-color: white;
      padding-top: 1rem;
      padding-bottom: 1rem;
      box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.3);
    }
    .rbookstitle {
      border: black;
      font-size: 1rem;
      font-weight: 700;
      
    }
    .madeby {
      text-align: center;
      font-family: monospace;
      
    }

  }
  
  @media (max-width: 1024px) {
    .book-box {
      width: calc(50% - 20px);
    }

    

  }
  
  @media (max-width: 768px) {
    .book-box {
      width: 100%;
  
    }

    .madeby {
      text-align: center;
      font-family: monospace;
      
    }
  }
/* Add this to your AddBook.css */
.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: orange;
}

.card-text {
  margin:5px;
}
.addtolib {
  
  
  bottom: 10px;
  left: 10px;
  width: 3rem;
  align-self:flex-start;
  

}
.addtolib:hover {
  visibility: visible;
}

.user-books {
  border: 1px;
  border: grey;
}

.card-body1 {
  display: flex;
  font-size: smaller;
}

.book-image1 img {
  width: 100px;
  
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 5px;
}

.book-content {
  font-size: smaller;
  margin: 1%;
  flex-grow: 1;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-link {
  text-decoration: underline;
  font-family: sans-serif;
}

.emptyspace {

  margin-bottom: 18%;
  font-display: white;
}
