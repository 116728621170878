.community-container {
    margin: 20px;
  }
  
  
  .profile-click {
    color: blue;
    cursor: pointer !important;
  }

  .profile-click:hover {
    color: blue;
    cursor: pointer;;
  }
  .add-book-form {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-control {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .form-check-label {
    margin-left: 5px;
  }
  
  .btn-primary {
    background-color: #0056b3;
    border-color: #004085;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #004085;
  }
  
  .book-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
  }

 
  