

.header .navbar {
  display: flex;
  background-color: #D9D9D9;
  justify-content: space-between;
align-items: center;
}


.header .btn {
    padding: 8px 15px;
    margin-left: 10px;
    background-color: #284B63; /* Primary color */
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .header .btn:hover {
    background-color: #353535; /* Darker shade for hover effect */
    color: white;
    text-decoration: none;
  }
  
  .nav-link {
    margin-right: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .nav-link:hover {
    color: #353535;
  }
  

.burger-menu {
display: none;
cursor: pointer;
}

.burger-menu .burger-bar {
width: 25px;
height: 3px;
background-color: #333;
margin: 4px 0;
transition: 0.4s;
}

.navbar-links {
display: flex;
align-items: center;
}

.navbar-links a {
margin-left: 20px;
text-decoration: none;
color: #333;
}

/* Styling for active menu */
.navbar-links.active {
display: block;
}

/* Responsive Styling */
@media (max-width: 768px) {
.burger-menu {
display: block;
}

.navbar-links {
display: none;
flex-direction: column;
width: 100%;
}

.navbar-links a {
margin: 10px 0;
text-align: center;
}

/* Additional styling for when menu is open */
.navbar-links.active {
display: flex;
}

}