/* MyBooks.css */
.mybooks-container {
    padding: 20px;
  }
  
  
  .books-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .alert {
    position: fixed;
    bottom: 20px;
    left: 30%;
    background-color: #333;
    color: aliceblue;
    width: 30%;

  }
  
  
  .book-card {
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
    position: relative; 
    /* Set position relative for absolute positioning of child elements */
  }

  .book-card p .profile-click {
    color: blue;
    cursor: pointer !important;
  }

  .book-card p .profile-click:hover {
    color: blue;
    cursor: pointer;;
  }
  
  .wishlist {
    position: absolute; /* Position the button absolutely within the .book-card */
    top: 10px;
    right: 10px;
    background: none;
    width: 30px;
    border: none;
    cursor: pointer;
     
  }

 
  
  .book-image {
    width: 30%;
    height: auto;
    max-height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .book-info {
    text-align: left; /* Align text to the left */
  }
  
  .book-card h3 {
    font-size: 1.2em;
    color: #333;
    margin-top: 0;
  }
  
  .book-card p {
    font-size: 1em;
    color: #666;
  }
  
  /* Add responsive styles below as needed */
  @media (max-width: 768px) {
    .books-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .books-grid {
      grid-template-columns: 1fr;
    }
  }
  