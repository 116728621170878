/* HomePage.css */
.image-container img {
    max-width: 90%; /* Limit the maximum width to 100% of the container */
    height: 300px; /* Maintain aspect ratio */
    object-fit: contain; /* Fit the image within the container */
    border-radius: 2px;
    border-color: black;
    border-style: double;
    border-width: 1px;
  }
  