.messages-container {
  max-height: 700px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.message {
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  display: inline-block;
  max-width: 80%;
  word-wrap: break-word;
  clear: both;
}

.message.user {
  background-color: #007bff;
  color: white;
  float: right;
  margin-right: 8px;
  align-self: flex-end;
}

.message.character {
  background-color: #f1f1f1;
  color: black;
  float: left;
  margin-left: 8px;
  align-self: flex-start;
}

.message-form {
  display: flex;
  align-items: center;
}

.copy-response-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  padding: 0;
  text-decoration: underline;
}

.image-container img {
  max-width: 100%; /* Limit the maximum width to 100% of the container */
  height: auto; /* Maintain aspect ratio */
}
